<template>
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.85 5.2499C23.85 5.66412 24.1858 5.9999 24.6 5.9999C25.0142 5.9999 25.35 5.66412 25.35 5.2499V1.6499C25.35 1.23569 25.0142 0.899902 24.6 0.899902C24.1858 0.899902 23.85 1.23569 23.85 1.6499V5.2499ZM17.25 9.1499C17.25 9.56412 17.5858 9.8999 18 9.8999C18.4142 9.8999 18.75 9.56412 18.75 9.1499V6.4499C18.75 6.03569 18.4142 5.6999 18 5.6999C17.5858 5.6999 17.25 6.03569 17.25 6.4499V9.1499ZM8.85002 20.2499C8.85002 20.6641 9.18581 20.9999 9.60002 20.9999C10.0142 20.9999 10.35 20.6641 10.35 20.2499V16.6499C10.35 16.2357 10.0142 15.8999 9.60002 15.8999C9.18581 15.8999 8.85002 16.2357 8.85002 16.6499V20.2499ZM2.40002 22.4999C1.98581 22.4999 1.65002 22.1641 1.65002 21.7499V19.6499C1.65002 19.2357 1.98581 18.8999 2.40002 18.8999C2.81424 18.8999 3.15002 19.2357 3.15002 19.6499V21.7499C3.15002 22.1641 2.81424 22.4999 2.40002 22.4999ZM6.30002 23.0999C5.88581 23.0999 5.55002 22.7641 5.55002 22.3499V19.6499C5.55002 19.2357 5.88581 18.8999 6.30002 18.8999C6.71424 18.8999 7.05002 19.2357 7.05002 19.6499V22.3499C7.05002 22.7641 6.71424 23.0999 6.30002 23.0999ZM12.15 17.5499C12.15 17.9641 12.4858 18.2999 12.9 18.2999C13.3142 18.2999 13.65 17.9641 13.65 17.5499L13.65 15.4499C13.65 15.0357 13.3142 14.6999 12.9 14.6999C12.4858 14.6999 12.15 15.0357 12.15 15.4499V17.5499ZM21.3 7.4999C20.8858 7.4999 20.55 7.16412 20.55 6.7499V4.0499C20.55 3.63569 20.8858 3.2999 21.3 3.2999C21.7142 3.2999 22.05 3.63569 22.05 4.0499V6.7499C22.05 7.16412 21.7142 7.4999 21.3 7.4999ZM27.15 3.4499C27.15 3.86412 27.4858 4.1999 27.9 4.1999C28.3142 4.1999 28.65 3.86412 28.65 3.4499V1.6499C28.65 1.23569 28.3142 0.899902 27.9 0.899902C27.4858 0.899902 27.15 1.23569 27.15 1.6499V3.4499Z"
      fill="#40A9FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.00326996 3.26432C0.0574766 4.09097 0.771553 4.71716 1.59821 4.66295C4.18934 4.49304 10.5151 5.81229 13.7919 12.8028L13.8191 12.8609L13.8512 12.9165C15.3176 15.4548 16.9698 17.7499 19.2669 19.4635C21.5903 21.1968 24.4652 22.2563 28.2486 22.5128C29.0751 22.5688 29.7906 21.9442 29.8466 21.1177C29.9027 20.2911 29.278 19.5757 28.4515 19.5197C25.1549 19.2962 22.8548 18.3973 21.0607 17.0589C19.254 15.7111 17.8538 13.8394 16.4801 11.4698C12.5485 3.15186 4.90242 1.43984 1.40191 1.66938C0.575254 1.72359 -0.0509368 2.43767 0.00326996 3.26432Z"
      fill="#40A9FF"
    />
  </svg>
</template>
